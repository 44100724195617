import { Global } from 'shared/styled/global';
import { config, dom } from '@fortawesome/fontawesome-svg-core';
import { Settings as LuxonSettings } from 'luxon';
import React from 'react';

import { IntlProvider } from 'react-intl';
import 'shared/browser-check';
import globalStyle from 'shared/global-style';
import { closeSignUpModal } from 'shared/redux/globals/actions';
import SignupModal from 'shared/signup-modal';
import sharedFrench from 'shared/translations/fr.json';
import french from '../../translations/fr.json';
import { useAppDispatch, useAppSelector } from '../../store';
import 'fontsource-montserrat/latin.css';
import 'fontsource-open-sans/latin.css';
// Get a better image here

import { useRouter } from 'next/router';

config.autoAddCss = false;

const messages = {
  fr: { ...sharedFrench, ...french },
  en: {},
};

const LandingLayout = ({ children }) => {
  const dispatch = useAppDispatch();

  const isSignUpModalOpen = useAppSelector(
    (state) => state.globals.isSignUpModalOpen
  );

  const { locale: language } = useRouter();

  const userLocale =
    typeof window === 'undefined'
      ? globalThis['LOCALE'] || 'en-GB'
      : navigator.language;

  const localeCandidate =
    language.substring(0, 2) === userLocale.substring(0, 2)
      ? userLocale
      : language;

  const locale = Intl.NumberFormat.supportedLocalesOf([
    localeCandidate,
    'en-GB',
  ])[0];

  React.useEffect(() => {
    LuxonSettings.defaultLocale = locale;
  }, [language, locale]);

  return (
    <IntlProvider
      key={language}
      locale={locale}
      defaultLocale="en-GB"
      messages={messages[language.substring(0, 2)]}
    >
      <>
        <Global
          styles={(theme) => [
            globalStyle(theme),
            {
              body: {
                backgroundColor: theme.colors.background,
              },
            } as any,
          ]}
        />

        <style
          dangerouslySetInnerHTML={{
            __html: dom.css(),
          }}
        />
        {children}
        <SignupModal
          isOpen={isSignUpModalOpen}
          close={() => dispatch(closeSignUpModal())}
        />
      </>
    </IntlProvider>
  );
};

export default LandingLayout;
