import {
  arrow,
  autoUpdate,
  flip,
  FloatingFocusManager,
  offset,
  Placement,
  shift,
  size,
  Strategy,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import React from 'react';
import { Box, BoxProps, Flex, SxStyleProp } from 'shared/grid';
import Input from 'shared/input';
import { Text } from 'shared/typography';
import { useDebounce } from '../hooks';
import useIsomorphicLayoutEffect from '../hooks/use-isomorphic-layout-effect';
import { useUpdateEffect } from '../hooks/use-update-effect';
import { Arrow2 } from '../popper-arrow';
import Portal from '../portal';
import { highlight, score } from '../search';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'shared/utils/constants';

interface ItemType {
  value: any;
  label: string; // | React.JSX.Element;
  iconElement?: React.JSX.Element;
  searchText?: string;
  extra?: any;
  groupId?: string;
  score?: number;
  sx?: SxStyleProp;
}

interface Group {
  label: string;
  iconElement?: React.JSX.Element;
}

interface DropdownSelectProps {
  onChange: (any) => void;
  value: any;
  itemToString: (any) => string;
  items: ItemType[];
  staticItems?: readonly ItemType[];
  staticItemsEnd?: readonly ItemType[];
  groups?: Record<string, Group>;
  renderToggle: (
    props: Record<string, any>,
    attributes: { isOpen: boolean; displayString: string; items: ItemType[] }
  ) => React.JSX.Element;
  usePortal?: boolean;
  placement?: Placement;
  strategy?: Strategy;
  label?: React.JSX.Element | string;
  className?: string;
  truncate?: number;
  borderWidth?: number;
  arrowSize?: number;
  searchExtra?: boolean;
}

export function DropdownSelect({
  ref,
  onChange,
  value,
  items,
  staticItems = EMPTY_ARRAY,
  staticItemsEnd = EMPTY_ARRAY,
  groups = EMPTY_OBJECT,
  itemToString,
  usePortal = false,
  placement: placement_ = 'bottom',
  strategy: strategy_ = 'absolute',
  label = 'Select',
  renderToggle,
  truncate,
  borderWidth = 1,
  arrowSize = 8,
  searchExtra = false,
  ...rest
}: DropdownSelectProps & BoxProps) {
  staticItems = staticItems || EMPTY_ARRAY;
  staticItemsEnd = staticItemsEnd || EMPTY_ARRAY;

  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const listRef = React.useRef<Array<HTMLElement | null>>([]);

  const noResultsId = useId();
  const buttonId = useId();
  const listboxId = useId();

  const debouncedInputValue = useDebounce(
    search,
    items.length > 1000 ? 500 : 0
  );

  const searchString =
    debouncedInputValue?.[0] == '0'
      ? debouncedInputValue.substring(1)
      : debouncedInputValue;

  const filteredItems = React.useMemo(() => {
    let filteredItems = items;

    if (searchString) {
      filteredItems = items
        .map((item) => ({
          ...item,
          score: score(
            item.label + (item.extra ? ` ${item.extra}` : ''),
            searchString
          ),
        }))
        .filter((item) => (searchString ? item.score > 0 : true));

      filteredItems = filteredItems.sort((a, b) =>
        a.score > b.score ? -1 : 1
      );
    }

    filteredItems = filteredItems.filter(
      (_, idx) => !truncate || idx < truncate
    );
    return filteredItems;
  }, [items, searchString, truncate]);

  const [idxToGroupHeading, groupedItems] = React.useMemo(() => {
    const groupMap = filteredItems.reduce((groups, item) => {
      const key = item.groupId || 'default';
      groups[key] = groups[key] || [];
      groups[key].push(item);
      return groups;
    }, {});

    const groupList = Object.values<any>(groupMap).sort((a, b) =>
      a[0].score < b[0].scored ? -1 : 1
    );

    if (groupList.length < 2) {
      return [{}, staticItems.concat(filteredItems).concat(staticItemsEnd)];
    }

    let itemIdx = 0;

    const idxToGroupHeading = {};
    const groupedItems = [];

    for (const group of groupList) {
      for (let i = 0; i < group.length; i++) {
        const item = group[i];
        if (i === 0) {
          idxToGroupHeading[itemIdx + staticItems.length] =
            groups[item.groupId];
        }
        groupedItems.push(item);
        itemIdx++;
      }
    }

    return [
      idxToGroupHeading,
      staticItems.concat(groupedItems).concat(staticItemsEnd),
    ];
  }, [filteredItems, groups, staticItems, staticItemsEnd]);

  const MaybePortal = usePortal ? Portal : React.Fragment;

  const arrowRef = React.useRef(null);

  const {
    x,
    y,
    floatingStyles,
    strategy,
    refs,
    update,
    context,
    placement,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    middleware: [
      size({
        padding: 10,
      }),
      offset(5),
      flip(),
      shift(),
      arrow({ element: arrowRef }),
    ],
    placement: placement_,
    strategy: strategy_,
  });

  useIsomorphicLayoutEffect(() => {
    // IMPORTANT: When the floating element first opens, this effect runs when
    // the styles have **not yet** been applied to the element. A rAF ensures
    // we wait until the position is ready, and also runs before paint.
    // https://floating-ui.com/docs/react-dom#effects
    requestAnimationFrame(() => {
      if (activeIndex != null) {
        listRef.current[activeIndex]?.scrollIntoView({ block: 'nearest' });
      }
    });
  }, [activeIndex]);

  // Handles opening the floating element via the Choose Emoji button.
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    // useFocus(context),
    useDismiss(context),
    useRole(context),
  ]);

  // Handles the list navigation where the reference is the inner input, not
  // the button that opens the floating element.
  const {
    getReferenceProps: getInputProps,
    getFloatingProps: getListFloatingProps,
    getItemProps,
  } = useInteractions([
    useListNavigation(context, {
      listRef,
      onNavigate: open ? setActiveIndex : undefined,
      activeIndex,
      focusItemOnOpen: false,
      virtual: true,
      loop: true,
      allowEscape: true,
    }),
  ]);

  useUpdateEffect(() => {
    if (open) {
      // nope
    } else {
      setSearch('');
      setActiveIndex(null);
      return;
    }
  }, [open]);

  // Prevent input losing focus on Firefox VoiceOver
  const { 'aria-activedescendant': ignoreAria, ...floatingProps } =
    getFloatingProps(
      getListFloatingProps({
        onKeyDown(event) {
          if (event.key === 'Tab') {
            setOpen(false);
          }
        },
      })
    );

  const handleItemClick = (value = null) => {
    if (value || activeIndex !== null) {
      onChange(value || filteredItems[activeIndex].value);
      setOpen(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent, value = null) => {
    if (event.key === 'Enter' && (value || activeIndex !== null)) {
      onChange(value || filteredItems[activeIndex].value);
      setOpen(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveIndex(null);
    setSearch(event.target.value);
  };

  return (
    <>
      {renderToggle(
        getReferenceProps({
          ref: refs.setReference,
          id: buttonId,
          'aria-label': 'Choose',
          ...(items.length > 10
            ? {}
            : getInputProps({
                onChange: handleInputChange,
                onKeyDown: handleKeyDown,
              })),
        }),
        {
          isOpen: open,
          displayString: itemToString(value),
          items,
        }
      )}

      <MaybePortal>
        {open && (
          <FloatingFocusManager context={context} initialFocus={0}>
            <Flex
              flexDirection="column"
              boxShadow="medium"
              borderRadius={2}
              color="text"
              sx={{
                border: `${borderWidth}px solid rgba(27, 31, 35, 0.15)`,
                backgroundColor: 'background',
                outline: 0,
              }}
              // style={{
              //   position: strategy,
              //   left: x ?? 0,
              //   top: y ?? 0,
              // }}
              aria-labelledby={buttonId}
              maxHeight="50vh"
              style={floatingStyles}
              ref={refs.setFloating}
              {...floatingProps}
              {...rest}
              zIndex={6002} // Higher than modal
            >
              {items.length > 10 && (
                <Box
                  m={2}
                  // display={items.length > 10 ? 'block' : 'none'}
                >
                  <Input
                    ref={inputRef}
                    placeholder="Search..."
                    value={search}
                    aria-controls={
                      filteredItems.length === 0 ? noResultsId : listboxId
                    }
                    aria-expanded="true"
                    aria-autocomplete="list"
                    {...getInputProps({
                      onChange: handleInputChange,
                      onKeyDown: handleKeyDown,
                    })}
                  />
                </Box>
              )}

              {items.length !== 0 && filteredItems.length === 0 && (
                <Text
                  m={3}
                  key={search}
                  id={noResultsId}
                  role="region"
                  aria-atomic="true"
                  aria-live="assertive"
                  fontWeight="bold"
                  textAlign="center"
                >
                  No results
                </Text>
              )}

              <Box overflow="auto" role="listbox" id={listboxId}>
                {groupedItems.map((item, index) => (
                  <React.Fragment key={`${item}${index}`}>
                    {idxToGroupHeading[index]?.label && (
                      <Flex
                        px={3}
                        py={2}
                        fontWeight="heading"
                        color="gray.4"
                        alignItems="center"
                      >
                        {idxToGroupHeading[index]?.iconElement && (
                          <Box mr={2}>
                            {idxToGroupHeading[index]?.iconElement}{' '}
                          </Box>
                        )}
                        {idxToGroupHeading[index].label}
                      </Flex>
                    )}
                    <Box
                      as="button"
                      type="button"
                      display="block"
                      width="100%"
                      key={item.value}
                      ref={(node) => {
                        listRef.current[index] = node;
                      }}
                      {...getItemProps({
                        onClick: (e) => handleItemClick(item.value),
                        // onKeyDown: (e) => handleKeyDown(e, item.value),
                      })}
                      color="text"
                      px={3}
                      py={'12px'}
                      sx={{
                        backgroundColor:
                          activeIndex === index ? 'gray.0' : 'transparent',
                        fontWeight: value === item.value ? 'heading' : 'normal',
                        fontFamily: 'body',
                        cursor: 'pointer',
                      }}
                    >
                      <Flex alignItems="center" sx={item.sx}>
                        {item.iconElement}

                        <Text
                          sx={{ wordBreak: 'normal' }}
                          // flex="1 1 200px"
                          ml={item.iconElement ? 3 : 0}
                          dangerouslySetInnerHTML={{
                            __html: searchString
                              ? highlight(item.label, searchString)
                              : item.label,
                          }}
                        />
                      </Flex>
                      {item.extra &&
                        (typeof item.extra === 'string' ? (
                          <Text
                            pt={1}
                            dangerouslySetInnerHTML={{
                              __html:
                                searchString && searchExtra
                                  ? highlight(item.extra, searchString)
                                  : item.extra,
                            }}
                          />
                        ) : (
                          item.extra
                        ))}
                    </Box>
                  </React.Fragment>
                ))}
              </Box>
              <Arrow2
                ref={arrowRef}
                placement={placement || placement_}
                styles={{
                  left: `${arrowX}px`,
                  top: `${arrowY}px`,
                }}
                borderWidth={borderWidth}
                arrowSize={arrowSize}
              />
            </Flex>
          </FloatingFocusManager>
        )}
      </MaybePortal>
    </>
  );
}

// DropdownSelect['whyDidYouRender'] = true;

export default DropdownSelect;
