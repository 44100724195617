import { fetchBooking } from '../bookings/actions';
import { fetchClient } from '../clients/actions';
import { updatePreferences } from '../user-preferences/actions';
import {
  CLOSE_BLOCK_MODAL,
  CLOSE_BOOKING_MODAL,
  CLOSE_CHANGE_PLAN_MODAL,
  CLOSE_CLIENT_IMPORT_MODAL,
  CLOSE_CLIENT_MODAL,
  CLOSE_HELP_MODAL,
  CLOSE_INVOICE_MESSAGE_MODAL,
  CLOSE_INVOICE_MODAL,
  CLOSE_NEW_BLOCK_MODAL,
  CLOSE_NEW_BOOKING_MODAL,
  CLOSE_NEW_BUSINESS_MODAL,
  CLOSE_NEW_INVOICE_MODAL,
  CLOSE_NEW_PAYMENT_MODAL,
  CLOSE_NEW_SYSTEM_INVOICE_MODAL,
  CLOSE_NEW_SYSTEM_PAYMENT_MODAL,
  CLOSE_PASSWORD_MODAL,
  CLOSE_PAYMENT_MODAL,
  CLOSE_SCHEDULE_MODAL,
  CLOSE_SIGN_UP,
  CLOSE_SYSTEM_INVOICE_MODAL,
  CLOSE_SYSTEM_PAYMENT_MODAL,
  CLOSE_TRIAL_END_MODAL,
  CLOSE_UPDATE_MODAL,
  CLOSE_USER_MODAL,
  DISABLE_NEW_SLOT_SELECT,
  ENABLE_NEW_SLOT_SELECT,
  NEW_SLOT_SELECTED,
  OPEN_BLOCK_MODAL,
  OPEN_BOOKING_MODAL,
  OPEN_CHANGE_PLAN_MODAL,
  OPEN_CLIENT_IMPORT_MODAL,
  OPEN_CLIENT_MODAL,
  OPEN_HELP_MODAL,
  OPEN_INVOICE_MESSAGE_MODAL,
  OPEN_INVOICE_MODAL,
  OPEN_NEW_BLOCK_MODAL,
  OPEN_NEW_BOOKING_MODAL,
  OPEN_NEW_BUSINESS_MODAL,
  OPEN_NEW_INVOICE_MODAL,
  OPEN_NEW_PAYMENT_MODAL,
  OPEN_NEW_SYSTEM_INVOICE_MODAL,
  OPEN_NEW_SYSTEM_PAYMENT_MODAL,
  OPEN_PASSWORD_MODAL,
  OPEN_PAYMENT_MODAL,
  OPEN_SCHEDULE_MODAL,
  OPEN_SIGN_UP,
  OPEN_SYSTEM_INVOICE_MODAL,
  OPEN_SYSTEM_PAYMENT_MODAL,
  OPEN_TRIAL_END_MODAL,
  OPEN_UPDATE_MODAL,
  OPEN_USER_MODAL,
  SET_CLIENT_MODAL_TAB,
} from './action-types';

export const openTrialEndModal = () => ({ type: OPEN_TRIAL_END_MODAL });
export const closeTrialEndModal = () => ({ type: CLOSE_TRIAL_END_MODAL });

export const openUpdateModal = () => ({ type: OPEN_UPDATE_MODAL });
export const closeUpdateModal = () => ({ type: CLOSE_UPDATE_MODAL });
export const openSignUpModal = () => ({ type: OPEN_SIGN_UP });
export const closeSignUpModal = () => ({ type: CLOSE_SIGN_UP });

export const openInvoiceMessageModal = (id: string) => ({
  type: OPEN_INVOICE_MESSAGE_MODAL,
  payload: id,
});
export const closeInvoiceMessageModal = () => ({
  type: CLOSE_INVOICE_MESSAGE_MODAL,
});

export const closeInvoiceModal = () => ({ type: CLOSE_INVOICE_MODAL });

export const openInvoiceModal = (id: string) => (dispatch, getState) => {
  return new Promise<void>((resolve) => {
    const open = () => {
      dispatch({
        type: OPEN_INVOICE_MODAL,
        payload: id,
      });
      resolve();
    };

    const state = getState();

    if (state.globals.invoiceModalId) {
      dispatch(closeInvoiceModal());
      // const invoiceStatus =
      //   state.invoices.data[state.globals.invoiceModalId]?.status;

      if (state.globals.invoiceModalId === 'new') {
        open();
      } else {
        // Prevent loading same modal with different data
        setTimeout(open, 1000);
      }
    } else {
      open();
    }
  });
};

export const openNewInvoiceModal = (clientId = null, bookingId = null) => ({
  type: OPEN_NEW_INVOICE_MODAL,
  payload: { clientId, bookingId },
});
export const closeNewInvoiceModal = () => ({ type: CLOSE_NEW_INVOICE_MODAL });

export const closePaymentModal = () => ({ type: CLOSE_PAYMENT_MODAL });
export const openPaymentModal = (id: string) => (dispatch, getState) => {
  const open = () =>
    dispatch({
      type: OPEN_PAYMENT_MODAL,
      payload: id,
    });

  const state = getState();

  if (state.globals.paymentModalId) {
    dispatch(closePaymentModal());
    setTimeout(open, 1000);
  } else {
    open();
  }
};

export const openNewPaymentModal = (clientId = null, invoiceId = null) => ({
  type: OPEN_NEW_PAYMENT_MODAL,
  payload: { clientId, invoiceId },
});
export const closeNewPaymentModal = () => ({ type: CLOSE_NEW_PAYMENT_MODAL });

export const openNewBusinessModal = () => ({ type: OPEN_NEW_BUSINESS_MODAL });
export const closeNewBusinessModal = () => ({ type: CLOSE_NEW_BUSINESS_MODAL });

export const openUserModal = (id) => ({ type: OPEN_USER_MODAL, payload: id });
export const closeUserModal = () => ({ type: CLOSE_USER_MODAL });

export const openHelpModal = (id) => ({ type: OPEN_HELP_MODAL, payload: id });
export const closeHelpModal = () => ({ type: CLOSE_HELP_MODAL });

export const closeClientModal = () => ({ type: CLOSE_CLIENT_MODAL });
export const openClientModal =
  (
    id,
    tab = 'profile',
    defaultSubject = '',
    defaultBody = '',
    noClose = false
  ) =>
  (dispatch, getState) => {
    const state = getState();
    if (!state.clients.data[id]) {
      dispatch(fetchClient(id)).then((client) => {
        if (state.userPreferences.currentBusinessId !== client.business_id) {
          dispatch(
            updatePreferences({ currentBusinessId: client.business_id })
          );
        }
      });
    }
    const open = () =>
      dispatch({
        type: OPEN_CLIENT_MODAL,
        payload: { id, tab, defaultSubject, defaultBody },
      });

    if (!noClose && state.globals.clientModalId) {
      dispatch(closeClientModal());
      setTimeout(open, 1000);
    } else {
      open();
    }
  };

export const setClientModalTab = (tab) => ({
  type: SET_CLIENT_MODAL_TAB,
  payload: tab,
});

export const openClientImportModal = () => ({ type: OPEN_CLIENT_IMPORT_MODAL });
export const closeClientImportModal = () => ({
  type: CLOSE_CLIENT_IMPORT_MODAL,
});

export const closeBookingModal = () => ({ type: CLOSE_BOOKING_MODAL });
export const openBookingModal =
  (id, forceModal = false, firstClose = true) =>
  (dispatch, getState) => {
    if (!id) {
      try {
        throw new Error(`Booking id ${id} not valid`);
      } catch (error) {
        Sentry.captureException(error);
      }
      // console.error(`Booking id ${id} not valid`);
      // Sentry.captureMessage(`Booking id ${id} not valid`, 'error');
      return;
    }
    const state = getState();

    if (id && !state.bookings.data[id]) {
      dispatch(fetchBooking(id)).then((booking) => {
        if (state.userPreferences.currentBusinessId !== booking.business_id) {
          dispatch(
            updatePreferences({ currentBusinessId: booking.business_id })
          );
        }
      });
    } else {
      const booking = state.bookings.data[id];
      if (
        state.userPreferences.currentBusinessId !==
        state.bookings.data[id].business_id
      ) {
        dispatch(updatePreferences({ currentBusinessId: booking.business_id }));
      }
    }

    const open = () =>
      dispatch({ type: OPEN_BOOKING_MODAL, payload: id, meta: { forceModal } });

    if (state.globals.bookingModalId && firstClose) {
      dispatch(closeBookingModal());
      setTimeout(open, 1000);
    } else {
      open();
    }
  };

export const openNewBookingModal = (slot) => ({
  type: OPEN_NEW_BOOKING_MODAL,
  payload: slot,
});

export const closeNewBookingModal = () => ({ type: CLOSE_NEW_BOOKING_MODAL });

export const openScheduleModal = (id, slot = null) => ({
  type: OPEN_SCHEDULE_MODAL,
  payload: { id, slot },
});
export const closeScheduleModal = () => ({ type: CLOSE_SCHEDULE_MODAL });

export const openNewBlockModal = (slot) => ({
  type: OPEN_NEW_BLOCK_MODAL,
  payload: slot,
});
export const closeNewBlockModal = () => ({ type: CLOSE_NEW_BLOCK_MODAL });

export const openBlockModal = (id) => ({ type: OPEN_BLOCK_MODAL, payload: id });
export const closeBlockModal = () => ({ type: CLOSE_BLOCK_MODAL });

export const openPasswordModal = () => ({ type: OPEN_PASSWORD_MODAL });
export const closePasswordModal = () => ({ type: CLOSE_PASSWORD_MODAL });

export const enableNewSlotSelect = () => ({
  type: ENABLE_NEW_SLOT_SELECT,
});
export const disableNewSlotSelect = () => ({ type: DISABLE_NEW_SLOT_SELECT });
export const newSlotSelected = () => ({
  type: NEW_SLOT_SELECTED,
});

export const closeSystemInvoiceModal = () => ({
  type: CLOSE_SYSTEM_INVOICE_MODAL,
});

export const openSystemInvoiceModal = (id: string) => (dispatch, getState) => {
  return new Promise<void>((resolve) => {
    const open = () => {
      dispatch({
        type: OPEN_SYSTEM_INVOICE_MODAL,
        payload: id,
      });
      resolve();
    };

    const state = getState();

    if (state.globals.bookemInvoiceModalId) {
      dispatch(closeSystemInvoiceModal());

      // const invoiceStatus =
      //   state.systemInvoices.data[state.globals.bookemInvoiceModalId]?.status;

      if (state.globals.bookemInvoiceModalId === 'new') {
        open();
      } else {
        // Prevent loading same modal with different data
        setTimeout(open, 1000);
      }
    } else {
      open();
    }
  });
};

export const openNewSystemInvoiceModal = (businessId = null) => ({
  type: OPEN_NEW_SYSTEM_INVOICE_MODAL,
  payload: { businessId },
});
export const closeNewSystemInvoiceModal = () => ({
  type: CLOSE_NEW_SYSTEM_INVOICE_MODAL,
});

export const closeSystemPaymentModal = () => ({
  type: CLOSE_SYSTEM_PAYMENT_MODAL,
});
export const openSystemPaymentModal = (id: string) => (dispatch, getState) => {
  const open = () =>
    dispatch({
      type: OPEN_SYSTEM_PAYMENT_MODAL,
      payload: id,
    });

  const state = getState();

  if (state.globals.paymentModalId) {
    dispatch(closeSystemPaymentModal());
    setTimeout(open, 1000);
  } else {
    open();
  }
};

export const openNewSystemPaymentModal = (
  businessId = null,
  invoiceId = null
) => ({
  type: OPEN_NEW_SYSTEM_PAYMENT_MODAL,
  payload: { businessId, invoiceId },
});
export const closeNewBookemPaymentModal = () => ({
  type: CLOSE_NEW_SYSTEM_PAYMENT_MODAL,
});

export const openChangePlanModal = (businessId = null) => ({
  type: OPEN_CHANGE_PLAN_MODAL,
  payload: { businessId },
});

export const closeChangePlanModal = () => ({ type: CLOSE_CHANGE_PLAN_MODAL });
