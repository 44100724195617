// import {
//   AsYouType,
//   format,
//   getPhoneCode,
//   parse,
//   isSupportedCountry,
// } from 'libphonenumber-js';
import React from 'react';
import countryItems_ from 'shared/countries';
import Input from 'shared/input';
import Autocomplete from '../autocomplete';
import InputGroup from '../input-group';
import { useSelector } from 'react-redux';
import { useLibPhoneNumber } from 'shared/hooks/phone-number';

interface Props {
  value: string;
  onChange: (any) => void;
  style?: React.CSSProperties;
  className?: string;
  onFocus?: any;
  onBlur?: any;
  placeholder?: string;
  tabIndex?: number;
  invalid?: boolean;
  name?: string;
  labelId?: string;
  autoComplete?: string;
  id?: string;
}

const inputUnformat = (value) => value.replace(/ /g, '');

function PhoneInput({
  ref,
  value,
  onChange,
  className = '',
  onFocus,
  onBlur,
  placeholder,
  tabIndex,
  invalid,
  name,
  labelId,
  autoComplete,
  id,
  ...rest
}: Props & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  const { AsYouType, format, getPhoneCode, parse, isSupportedCountry } =
    useLibPhoneNumber();

  const getOutputValue = React.useCallback(
    (country, inputValue) => {
      const parsedNumber = parse(inputValue, country);
      const outputValue =
        Object.keys(parsedNumber).length !== 0
          ? format(parsedNumber, 'E.164')
          : inputValue;

      return outputValue;
    },
    [format, parse]
  );

  const inputFormat = React.useCallback(
    (inputText, country) => {
      const formatter = new AsYouType(country);
      return formatter.input(inputText);
    },
    [AsYouType]
  );

  const defaultCountry = useSelector(
    (state: any) => state.geoip.country_code
  ) as string;

  const countryItems = React.useMemo(
    () => countryItems_.filter(({ value }) => isSupportedCountry(value)),
    [isSupportedCountry]
  );

  const [country, setCountry] = React.useState(defaultCountry);
  const [inputValue, setInputValue] = React.useState('');

  const onSelectChange = React.useCallback(
    (nextCountry) => {
      const oldCode = country && getPhoneCode(country as any);

      let newCode;
      try {
        newCode = nextCountry && getPhoneCode(nextCountry);
      } catch {
        newCode = '';
      }

      let nextInputValue = inputValue;

      const formatter = new AsYouType(nextCountry || (defaultCountry as any));
      formatter.input(nextInputValue);

      if (!nextInputValue || nextInputValue === '+' || !formatter['country']) {
        nextInputValue = newCode ? `+${newCode}` : '';
      } else if (oldCode) {
        nextInputValue = nextInputValue.replace(
          new RegExp(`^\\+${oldCode}`),
          `+${newCode}` as string
        );
      }

      setCountry(nextCountry);
      setInputValue(inputFormat(nextInputValue, nextCountry || defaultCountry));
      onChange(getOutputValue(nextCountry, nextInputValue));
    },
    [
      AsYouType,
      country,
      defaultCountry,
      getOutputValue,
      getPhoneCode,
      inputFormat,
      inputValue,
      onChange,
    ]
  );

  const onInputChange = React.useCallback(
    (e) => {
      const cursorStart = e.target.selectionStart;
      const cursorEnd = e.target.selectionEnd;
      const isAtEnd = cursorEnd === e.target.value.length;

      const nextValue = inputUnformat(e.target.value);
      let nextCountry = country;

      const formatter = new AsYouType(country || (defaultCountry as any));
      formatter.input(nextValue); // +1-310-555-1212

      if (nextValue[0] === '+') {
        if (nextValue.length === 1) {
          nextCountry = null;
        } else if (formatter.country) {
          nextCountry = formatter.country;
        }
      }

      setCountry(nextCountry);
      setInputValue(inputFormat(nextValue, nextCountry || defaultCountry));

      const target = e.target;
      const outputValue = getOutputValue(
        country || (defaultCountry as any),
        nextValue
      );
      onChange(outputValue);

      if (!isAtEnd) {
        setTimeout(() => target.setSelectionRange(cursorStart, cursorEnd), 50);
      }
    },
    [AsYouType, country, defaultCountry, getOutputValue, inputFormat, onChange]
  );

  React.useEffect(() => {
    const { country: nextCountry, phone: nextPhone } = parse(value);
    const { country: currentCountry, phone: currentPhone } = parse(
      inputValue,
      country as any
    );

    if (currentCountry === nextCountry && currentPhone === nextPhone) {
      return;
    }

    const outputValue = getOutputValue(country, value);
    if (outputValue !== value) {
      onChange(outputValue);
    }

    setCountry(nextCountry);
    setInputValue(inputFormat(value, country || currentCountry));
  }, [
    country,
    defaultCountry,
    getOutputValue,
    inputFormat,
    inputValue,
    onChange,
    parse,
    value,
  ]);

  return (
    <InputGroup ref={ref} {...rest}>
      <Autocomplete
        // autoComplete="new-country-code"
        id={(id || '') + '-country-code'}
        name={(name || '') + '-country-code'}
        labelId={labelId}
        tabIndex={tabIndex}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder="Country"
        className={className}
        value={country}
        onChange={onSelectChange}
        sx={{
          display: 'block',
          flexShrink: 1,
          '& input': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            // borderRightWidth: '2px',
          },
        }}
        itemToString={(item) =>
          countryItems.find((v) => v.value === item)?.label || ''
        }
        items={countryItems}
        invalid={invalid}
      />

      <Input
        id={id}
        autoComplete={autoComplete}
        name={name}
        tabIndex={tabIndex}
        onBlur={onBlur}
        onFocus={onFocus}
        sx={{
          flexGrow: 1,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeftWidth: '0.5px',
        }}
        className={className}
        placeholder={placeholder}
        type="tel"
        onChange={onInputChange}
        value={inputValue}
        invalid={invalid}
      />
    </InputGroup>
  );
}

export default PhoneInput;
