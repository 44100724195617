import {
  faBars,
  faChevronDown,
  faGlobe,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { default as NextLink } from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'shared/button';
import { DropdownMenu } from 'shared/dropdown';
import DropdownSelect from 'shared/dropdown-select';
import { Box, Container, Flex } from 'shared/grid';
import Logo from 'shared/images/logo.svg';
import { ModalToastContext } from 'shared/modal/context';
import { openSignUpModal } from 'shared/redux/globals/actions';
import settings from 'shared/settings';
import Toaster from 'shared/toaster';
import { languages } from 'shared/utils/constants';
import { useAppDispatch } from '../../store';
import { ScrollButton } from '../landing-shared';
import MobileMenu from './mobile-menu';
import ColorMode from 'shared/color-modes';
import { Link } from 'shared/typography';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'shared/utils/constants';

export const TOPBAR_HEIGHT_PX = 60;
export const TOPBAR_HEIGHT = `${TOPBAR_HEIGHT_PX}px`;

const TopMenu = ({ links, sx = {}, ...rest }) => {
  return (
    <Flex
      {...rest}
      as="nav"
      display={['none', null, 'flex']}
      // py={4}
      height="100%"
      alignItems="center"
      justifyContent="space-around"
      flex="0 0 460px"
      sx={{
        ...sx,
        'a, button': {
          fontFamily: 'heading',
          fontWeight: 'heading',
          color: 'inherit',
        },
      }}
    >
      {links.map((v, idx) => {
        if (v.type === 'linkGroup') {
          // return <Box key={idx}>{v.text}</Box>;
          return (
            <DropdownMenu
              key={idx}
              placement="bottom"
              toggle={(props) => (
                <Box as="button" {...props}>
                  {v.text} <FontAwesomeIcon icon={faChevronDown} />
                </Box>
              )}
            >
              {v.links.map((v, idx) => {
                if (v.href[0] === '#') {
                  return (
                    <li key={idx}>
                      <ScrollButton
                        activeClass="active"
                        to={v.href.substring(1)}
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        {v.text}
                      </ScrollButton>
                    </li>
                  );
                } else if (v.href[0] === '/') {
                  return (
                    <li key={idx}>
                      <Link as={NextLink} href={v.href}>
                        {v.text}
                      </Link>
                    </li>
                  );
                } else {
                  return (
                    <li key={idx}>
                      <Link href={v.href} target="_blank">
                        {v.text}
                      </Link>
                    </li>
                  );
                }
              })}
            </DropdownMenu>
          );
        } else if (v.href[0] === '#') {
          return (
            <ScrollButton
              key={idx}
              activeClass="active"
              to={v.href.substring(1)}
              spy={true}
              smooth={true}
              duration={500}
            >
              {v.text}
            </ScrollButton>
          );
        } else if (v.href[0] === '/') {
          return (
            <Link as={NextLink} key={idx} href={v.href}>
              {v.text}
            </Link>
          );
        } else {
          return (
            <Link key={idx} href={v.href} target="_blank">
              {v.text}
            </Link>
          );
        }
      })}
    </Flex>
  );
};

const useIsTop = () => {
  const tickingRef = React.useRef(false);
  const [isTop, setIsTop] = React.useState(true);

  React.useEffect(() => {
    const onScroll = () => {
      if (!tickingRef.current) {
        window.requestAnimationFrame(function () {
          setIsTop(window.scrollY === 0);
          tickingRef.current = false;
        });
        tickingRef.current = true;
      }
    };

    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return isTop;
};

const Header = ({
  isHome = false,
  links = EMPTY_ARRAY,
  settingsData = EMPTY_OBJECT,
  topBg = null,
}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { locale: language } = router;
  const { modalCount } = React.useContext(ModalToastContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const isTop = useIsTop();
  const transparentTopbar = !isMobileMenuOpen && isTop;

  return (
    <ColorMode mode={transparentTopbar ? topBg : undefined}>
      <Box
        sx={{
          zIndex: 100,
          transition: 'color 100ms ease, background-color 100ms ease',
          position: 'fixed',
          width: '100%',
          color: transparentTopbar ? 'transparentHeaderLogoColor' : 'primary',
          bg: transparentTopbar ? 'transparent' : 'white',
          '&::after': {
            content: '""',
            position: 'absolute',
            inset: '0',
            pointerEvents: 'none',
            opacity: transparentTopbar ? 0 : 1,
            transition: 'opacity 200ms ease 0s',
            boxShadow: 'rgb(16 25 40 / 8%) 0px 16px 32px 0px',
          },
          pr: 'var(--removed-body-scroll-bar-size)',
        }}
      >
        <Box>
          <Container
            px={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
              height: TOPBAR_HEIGHT,
              '.logo': {
                width: '121px',
              },
            }}
          >
            <Flex
              flex="1 0 100%"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
            >
              {isHome ? (
                <ScrollButton
                  activeClass="active"
                  to="top"
                  spy={true}
                  smooth={true}
                  duration={500}
                  display="inline-flex"
                  color="inherit"
                  // color={transparentTopbar ? 'inherit' : 'primary'}
                >
                  <Logo
                    className="logo"
                    alt="Bookem logo"
                    title="Bookem logo"
                  />
                </ScrollButton>
              ) : (
                <Link
                  as={NextLink}
                  href="/"
                  display="inline-flex"
                  color="inherit"
                >
                  <Logo
                    className="logo"
                    alt="Bookem logo"
                    title="Bookem logo"
                  />
                </Link>
              )}

              {links.length > 0 && (
                <Box
                  position="absolute"
                  width="100%"
                  height="100%"
                  sx={{
                    pointerEvents: 'none',
                    px: '230px',
                  }}
                >
                  <TopMenu
                    links={links}
                    sx={{
                      pointerEvents: 'all',
                    }}
                  />
                </Box>
              )}

              <Flex alignItems="center" flex="0 1 auto">
                <Box
                  // display={['none', null, 'block']}
                  mr={3}
                  sx={{
                    display: 'none',

                    '@media (max-width: 1399px)': {
                      display: 'block',
                    },

                    '@media (max-width: 1023px)': {
                      display: 'none',
                    },
                  }}
                >
                  <DropdownSelect
                    value={language}
                    items={Object.entries(languages).map(([k, v]) => ({
                      value: k,
                      label: v,
                    }))}
                    onChange={(v) => {
                      router.push(router.asPath, router.asPath, {
                        locale: v,
                      });
                    }}
                    itemToString={(item) => item}
                    renderToggle={(props) => (
                      <Link
                        as="button"
                        fontWeight="heading"
                        sx={{ textTransform: 'uppercase' }}
                        color="inherit"
                        {...props}
                      >
                        <FontAwesomeIcon icon={faGlobe} /> {language}
                      </Link>
                    )}
                  />
                </Box>

                <Button
                  color="brand1"
                  onClick={() => dispatch(openSignUpModal())}
                  className="show-for-medium"
                  sx={{
                    opacity: transparentTopbar ? 0 : 1,
                  }}
                >
                  <FormattedMessage
                    id="LandingPage.tryIt"
                    defaultMessage="Sign up"
                  />
                </Button>

                <Button
                  as="a"
                  ml={1}
                  color={transparentTopbar ? 'text' : 'primary'}
                  variant="outlined"
                  href={
                    __TARGET_ENV__ === 'production'
                      ? 'https://app.bookem.com/app'
                      : settings.getUrl('app', '/app')
                  }
                  sx={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  <FormattedMessage
                    id="LandingPage.signIn"
                    defaultMessage="Sign in"
                  />
                </Button>

                <Box
                  as="button"
                  title="menu"
                  pl={2}
                  color="inherit"
                  display={['block', null, 'none']}
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                >
                  <FontAwesomeIcon icon={faBars} size="lg" fixedWidth />
                </Box>

                {isMobileMenuOpen && (
                  <MobileMenu
                    isHome={isHome}
                    links={links}
                    close={() => setIsMobileMenuOpen(false)}
                  />
                )}
              </Flex>
            </Flex>
            <Box
              mr={2}
              sx={{
                display: 'none',
                '@media (min-width: 1400px)': {
                  position: 'absolute',
                  right: '2rem',
                  top: 0,
                  height: TOPBAR_HEIGHT,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                },
              }}
            >
              <DropdownSelect
                value={language}
                items={Object.entries(languages).map(([k, v]) => ({
                  value: k,
                  label: v,
                }))}
                onChange={(v) => {
                  router.push(router.asPath, router.asPath, {
                    locale: v,
                  });
                }}
                itemToString={(item) => item}
                renderToggle={(props) => (
                  <Link
                    as="button"
                    fontWeight="heading"
                    sx={{
                      textTransform: 'uppercase',
                      pr: 'var(--removed-body-scroll-bar-size)',
                    }}
                    color="inherit"
                    {...props}
                  >
                    <FontAwesomeIcon icon={faGlobe} /> {language}
                  </Link>
                )}
              />
            </Box>
          </Container>
        </Box>
      </Box>
      {modalCount === 0 && <Toaster />}
    </ColorMode>
  );
};

export default Header;
